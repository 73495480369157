<form [formGroup]="formGroup" (ngSubmit)="submitRegistration()">
  <div class="grid formgrid">
    <div class="field col-12 mb-3">
      <input
        class="w-full"
        type="email"
        placeholder="Work Email*"
        autocomplete="email"
        formControlName="email"
        required
        pInputText
      />
    </div>
    <div class="field col-12 lg:col-6 mb-3">
      <input
        class="w-full"
        autocomplete="given-name"
        placeholder="First Name*"
        formControlName="firstName"
        required
        pInputText
      />
    </div>
    <div class="field col-12 lg:col-6 mb-3">
      <input
        class="w-full"
        autocomplete="family-name"
        placeholder="Last Name*"
        formControlName="lastName"
        required
        pInputText
      />
    </div>
    <div class="field col-12 mb-3">
      <input
        class="w-full"
        autocomplete="company"
        placeholder="Company Name*"
        formControlName="company"
        required
        pInputText
      />
    </div>
    <div class="field col-12 mb-3">
      <p-inputMask
        class="border-0 p-0"
        styleClass="w-full"
        autocomplete="tel"
        placeholder="Phone Number*"
        formControlName="phone"
        mask="?(999) 999-9999"
        unmask="true"
        required
        pInputText
      ></p-inputMask>
    </div>
    <div class="field col-12 mb-3">
      <input
        #password
        (focus)="onPasswordFocus()"
        (blur)="onPasswordBlur()"
        class="w-full"
        placeholder="Password*"
        type="password"
        autocomplete="new-password"
        formControlName="password"
        required
        pInputText
        data-private="lipsum"
      />
      <p-overlay
        *ngIf="overlayVisible"
        [visible]="overlayVisible"
        [options]="overlayOptions"
        contentStyleClass="p-2 surface-overlay shadow-2 border-round"
      >
        <tc-password-requirements [password]="password.value"></tc-password-requirements>
      </p-overlay>
    </div>
  </div>
  <p class="text-center text-sm">
    By continuing, you agree to TaxCloud's
    <a class="no-underline text-sm" tcLink (click)="goToPrivacyPolicy()">Privacy Policy</a>,
    <a class="no-underline text-sm" tcLink (click)="goToTOS()">Terms of Service</a>.
  </p>
  <button
    [disabled]="(isValid$ | async) === false"
    pButton
    pRipple
    type="submit"
    label="Start Free Trial"
    class="p-button w-full mt-3 mb-2 border-round"
  ></button>
  <p class="flex justify-content-center align-items-center my-2">
    Already have an account?&nbsp;
    <button pButton pRipple label="Log In" class="p-button-text p-0" (click)="login()"></button>
  </p>
</form>
