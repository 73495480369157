import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { map, tap } from 'rxjs/operators'
import { SubSink } from 'subsink'

import { IRegister } from '../../models/iregister.model'
import { BehaviorSubject, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { OverlayOptions } from 'primeng/api'
import { PasswordRequirements } from '../../constants/user.constants'
@Component({
  selector: 'tc-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterFormComponent implements OnInit, OnDestroy {
  @ViewChild('password', { read: ElementRef, static: true }) passwordRef: ElementRef

  password: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.pattern('.*[0-9].*'),
    Validators.pattern('.*[a-z].*'),
    Validators.pattern('.*[A-Z].*'),
    Validators.pattern(/.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].*/),
  ])

  formGroup: UntypedFormGroup
  subs = new SubSink()
  confirm: UntypedFormControl = new UntypedFormControl('')
  currentRegistration$ = new BehaviorSubject<IRegister>(null)
  public publicSiteUrl = environment.publicSiteUrl
  public passWordStrength = new BehaviorSubject<string>(null)
  public overlayOptions: OverlayOptions = {}
  public overlayVisible: boolean = false
  public passwordRequirements = PasswordRequirements

  constructor(private fb: UntypedFormBuilder) {
    this.formGroup = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: this.password,
      confirm: this.confirm,
      phone: ['', Validators.required],
      company: ['', Validators.required],
    })
    this.overlayOptions = {
      responsive: { direction: 'bottom' },
      appendTo: this.passwordRef?.nativeElement,
    }
  }

  @Output() changeRegistration = new EventEmitter<IRegister>()
  @Output() goToLogin = new EventEmitter<boolean>()

  @Input('preFillForm')
  set preFillForm(email) {
    this.formGroup.get('email').setValue(email)
  }
  isValid$: Observable<boolean> = this.currentRegistration$.pipe(
    map((registration) => (registration && registration.isValid ? true : false))
  )

  ngOnInit(): void {
    this.subs.add(
      this.formGroup.valueChanges
        .pipe(
          tap((form: IRegister) => {
            this.currentRegistration$.next({ ...form, isValid: !this.formGroup.invalid })
          })
        )
        .subscribe()
    )

    this.subs.add(
      this.formGroup
        .get('password')
        .valueChanges.pipe(
          tap(() => {
            const allRequirementsValid = this.checkPasswordRequirementsValid()
            this.overlayVisible = !allRequirementsValid
          })
        )
        .subscribe()
    )
  }

  private checkPasswordRequirementsValid() {
    return this.passwordRequirements.every((req) => req.isValid(this.password.value))
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
  submitRegistration() {
    this.changeRegistration.emit(this.currentRegistration$.value)
  }
  login() {
    this.goToLogin.emit(true)
  }
  onPasswordFocus() {
    if (!this.checkPasswordRequirementsValid()) {
      this.overlayVisible = true
    }
  }
  onPasswordBlur() {
    this.overlayVisible = false
  }
  public goHome(): void {
    const url = new URL(`${this.publicSiteUrl}`)
    window.open(url.toString(), '_self')
  }
  public onStrengthChange(strength: number) {
    const strengthText =
      strength === 0
        ? 'Poor'
        : strength === 1
          ? 'Fair'
          : strength === 2
            ? 'Sufficient'
            : strength === 3
              ? 'Good'
              : 'Strong'
    this.passWordStrength.next(strengthText)
  }

  goToPrivacyPolicy() {
    window.open('https://taxcloud.com/legal/privacy-policy/', '_blank')
  }

  goToTOS(): void {
    window.open('https://taxcloud.com/legal/tos/', '_blank')
  }
}
