import { Component, OnInit, OnDestroy } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Subscription } from 'rxjs/internal/Subscription'
import { filter, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { IconService } from './root/services/icon.service'
import { GeminiService } from 'src/app/root/services/gemini.service'
import userflow from 'userflow.js'
import { PrimeNGConfig } from 'primeng/api'
import LogRocket from 'logrocket'
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core'
import { SubSink } from 'subsink'
import * as fromUserActions from 'src/app/user/actions/login.actions'
import * as fromUser from 'src/app/user/user.state'
import { Store } from '@ngrx/store'

declare const gtag
@Component({
  selector: 'tc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
  subs = new SubSink()
  events: string[] = []
  opened: boolean
  eventSub: Subscription
  appRouteSub: Subscription

  ONE_MINUTE = 60
  SECONDS_TILL_IDLE = 30 * this.ONE_MINUTE - 1

  constructor(
    private iconService: IconService,
    private router: Router,
    private geminiService: GeminiService,
    private primengConfig: PrimeNGConfig,
    private idleTimer: Idle,
    private userStore: Store<fromUser.State>
  ) {}

  ngOnInit() {
    this.primengConfig.ripple = true
    this.iconService.registerIcons()
    if (environment.gaTrackingId) {
      // register google tag manager
      const gTagManagerScript = document.createElement('script')
      gTagManagerScript.async = true
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaTrackingId}`
      document.head.appendChild(gTagManagerScript)

      // register google analytics
      const gaScript = document.createElement('script')
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
      `
      document.head.appendChild(gaScript)

      this.eventSub = this.router.events
        .pipe(
          filter((e) => e instanceof NavigationEnd),
          tap((e: NavigationEnd) =>
            gtag('config', environment.gaTrackingId, { page_path: e.urlAfterRedirects })
          )
        )
        .subscribe()
    }
    if (environment.production && environment.name !== 'staging') {
      this.appRouteSub = this.router.events
        .pipe(
          filter((e) => e instanceof NavigationEnd),
          tap(() => this.geminiService.sendGeminiAppRoute())
        )
        .subscribe()
    }

    // Init LogRocket
    if (environment.production) {
      LogRocket.init('atmvfa/taxcloud', {
        network: {
          requestSanitizer: (request) => {
            // Any password type requests
            if (
              request.url.toLowerCase().includes('login') ||
              request.url.toLowerCase().includes('password') ||
              request.url.toLowerCase().includes('register')
            ) {
              request.body = null
            }

            // Sanitize token
            if (request.headers['Authorization']) {
              request.headers['Authorization'] = 'redacted'
            }

            return request
          },
        },
      })
    }

    // Init Userflow
    if (!!environment.userflow.token) {
      userflow.init(environment.userflow.token)
    }

    // Idle state
    this.idleTimer.setIdle(this.SECONDS_TILL_IDLE)
    this.idleTimer.setTimeout(1)
    this.idleTimer.setInterrupts(DEFAULT_INTERRUPTSOURCES)

    this.subs.add(
      this.idleTimer.onTimeout.subscribe(() => {
        if (this.router.url.includes('sign-in')) return

        console.log('Timer Ran out: Locking app')
        this.lockApp()
      })
    )

    this.idleTimer.watch()
  }

  ngOnDestroy(): void {
    if (this.eventSub) {
      this.eventSub.unsubscribe()
    }
    if (this.appRouteSub) {
      this.appRouteSub.unsubscribe()
    }
  }

  public lockApp(): void {
    this.userStore.dispatch(new fromUserActions.LockApplication())
    this.router.navigate(['/sign-in'])
  }

  private isEnrichableEvent(event: any, context: any): boolean {
    const xhrTask = context.xhr?.__zone_symbol__xhrTask

    // Is it a network request?
    const isXhrRequest = event.type === 'resource' && event.resource.type === 'xhr'
    if (!isXhrRequest) {
      return false
    }

    // Is it a TaxCloud request?
    const taxCloudBaseUrls = [
      environment.baseUrl,
      environment.billingServiceUrl,
      environment.integrationsBaseUrl,
    ]
    const isTaxCloudRequest = taxCloudBaseUrls.some(
      (elem: string) => xhrTask?.data?.url?.toLowerCase?.()?.includes?.(elem)
    )
    if (!isTaxCloudRequest) {
      return false
    }

    // Is the request calling any sensitive routes?
    const sensitiveRoutes = ['login', 'resetpassword']
    const isSensitiveRequest = sensitiveRoutes.some(
      (elem: string) => xhrTask?.data?.url?.toLowerCase?.()?.includes?.(elem)
    )
    if (isSensitiveRequest) {
      return false
    }

    return true
  }
}
