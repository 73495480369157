import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core'
import { MatButtonToggleChange } from '@angular/material/button-toggle'
import { Router } from '@angular/router'
import * as fromRouter from '@ngrx/router-store'
import { select, Store } from '@ngrx/store'
import moment, { Moment } from 'moment'
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs'
import { filter, map, pluck, tap } from 'rxjs/operators'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'
import { SubSink } from 'subsink'

import * as fromAccount from '../../../../account/account.state'
import * as fromAccountSelect from '../../../../account/selectors/account.selectors'

interface IMonth {
  value: number
  label: string
  disabled: boolean
}

@Component({
  selector: 'tc-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.sass'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthPickerComponent implements OnInit, OnDestroy {
  public moment = moment
  startDate: Moment
  currentDate: BehaviorSubject<Moment> = new BehaviorSubject(null)
  year = moment().year()
  current: Moment = moment()
  subs = new SubSink()
  inputStartDate: BehaviorSubject<Moment> = new BehaviorSubject(null)
  shouldEmit = true
  selectedDay: number | null = null
  month: number = moment().month()

  months: Observable<IMonth[]> = of(
    Array.from({ length: 12 }).map((_, i: number) => {
      const monthDate = moment({ year: this.year, month: i })
      const isBeforeMin = this.minDate && monthDate.isBefore(this.minDate, 'month')
      const isAfterMax = this.maxDate && monthDate.isAfter(this.maxDate, 'month')

      return {
        label: moment().month(i).format('MMM'),
        value: i,
        disabled: isBeforeMin || isAfterMax,
      }
    })
  )

  constructor(
    private accountStore: Store<fromAccount.State>,
    private router: Router,
    private route: Store<fromRouter.RouterReducerState<any>>
  ) {}

  accountStartDate$: Observable<Moment> = this.accountStore.select(
    fromAccountSelect.selectStartDate
  )

  selectedMonth$: Observable<number> = this.currentDate.pipe(
    map((date) => (this.shouldEmit && date ? date.month() : null))
  )

  urlMonth$: Observable<string> = this.route.pipe(
    select(fromRouterSelectors.selectRouteState),
    pluck('state', 'queryParams', 'month')
  )

  get daysInMonth(): number[] {
    return Array.from(
      { length: moment({ year: this.year, month: this.month }).daysInMonth() },
      (_, i) => i + 1
    )
  }

  @Input() mode: 'month' | 'date' = 'month'
  @Input() minDate?: Moment
  @Input() maxDate?: Moment
  @Input() selectedDate: string

  @Input('firstMonth')
  set setStartMonth(month: string) {
    this.inputStartDate.next(moment(month, DateFormatEnum.Month))
  }

  @Input('resetToCurrent')
  set resetToCurrent(isReset: boolean) {
    if (isReset) {
      this.year = moment().year()
      this.month = moment().month()
      this.current = moment()
      this.currentDate.next(moment(this.current, DateFormatEnum.Month))
    }
  }

  @Input() defaultMonth: string
  @Input('selectedMonth')
  set setselectedMonth(month: string) {
    if (this.updateUrl) return
    if (!month) {
      this.currentDate.next(null)
      return
    }
    const selected = moment(month, DateFormatEnum.Month)
    if (selected.isValid()) {
      this.currentDate.next(selected)
    } else {
      console.error(`Month: ${month} is not ${DateFormatEnum.Month}`)
    }
  }

  @Input() updateUrl: boolean
  @Input() allowFutureDate = false

  @Output() monthChange = new EventEmitter<string>()

  ngOnInit() {
    this.subs.add(
      this.currentDate
        .pipe(
          filter((month) => !!month),
          filter(() => this.shouldEmit),
          map((month) =>
            this.mode === 'date'
              ? month.format(DateFormatEnum.Day)
              : month.format(DateFormatEnum.Month)
          ),
          tap((formattedDate) => this.monthChange.emit(formattedDate)),
          tap((formattedDate) => {
            if (this.updateUrl) {
              this.router.navigate([], {
                queryParams: { month: formattedDate },
                queryParamsHandling: 'merge',
              })
            }
          })
        )
        .subscribe()
    )

    this.subs.add(
      combineLatest([this.accountStartDate$, this.inputStartDate])
        .pipe(
          map(([account, input]) => (input === null || account.isAfter(input) ? account : input)),
          tap((startDate) => (this.startDate = startDate))
        )
        .subscribe()
    )

    this.subs.add(
      this.urlMonth$
        .pipe(
          tap((month) => {
            let selectedMonth: Moment
            if (month) {
              selectedMonth = moment(month, DateFormatEnum.Month)
            } else if (this.defaultMonth) {
              selectedMonth = moment(this.defaultMonth, DateFormatEnum.Month)
            } else {
              selectedMonth = moment().startOf('month')
            }

            if (selectedMonth.isValid()) {
              this.currentDate.next(selectedMonth)
            } else {
              console.error(`Month: ${month} is not ${DateFormatEnum.Month}`)
            }
          })
        )
        .subscribe()
    )

    if (this.selectedDate) {
      this.currentDate.next(moment(this.selectedDate, DateFormatEnum.Day))
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  incrementYear(event: Event) {
    event.stopPropagation()
    this.shouldEmit = false
    if (this.year >= moment().year() && !this.allowFutureDate) return
    if (this.maxDate && this.year >= this.maxDate.year()) return

    this.year++
    this.updateCurrentDateYear()
  }

  decrementYear(event: Event) {
    event.stopPropagation()
    this.shouldEmit = false
    if (this.year <= this.startDate.year()) return
    if (this.minDate && this.year <= this.minDate.year()) return

    this.year--
    this.updateCurrentDateYear()
  }

  selectDay(day: number) {
    this.shouldEmit = true
    const date = moment({ year: this.year, month: this.month, day })
    this.selectedDay = day
    if (this.isDayDisabled(day)) return
    this.currentDate.next(date)
  }

  private updateCurrentDateYear() {
    if (!this.currentDate.value) {
      this.currentDate.next(moment().year(this.year))
      return
    }
    this.currentDate.next(this.currentDate.value.clone().year(this.year))
  }

  chosenMonthHandler(event: MatButtonToggleChange) {
    this.shouldEmit = true
    this.month = event.value
    const base = this.currentDate.value ?? moment()
    const next = base.clone().year(this.year).month(event.value).startOf('month')
    this.currentDate.next(this.setNewDate(next, base, this.startDate))
  }

  private setNewDate(next: Moment, current: Moment, beginning: Moment): Moment {
    if (next.isBefore(beginning)) return beginning.clone().startOf('month')
    if (next.isAfter(moment()) && !this.allowFutureDate) return moment().startOf('month')
    return next.clone().startOf('month')
  }

  onPreviousMonth(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()

    if (this.month === 0) {
      if (!this.minDate || this.year > this.minDate.year()) {
        this.month = 11
        this.year--
      }
    } else {
      this.month--
    }
  }

  onNextMonth(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()

    if (this.month === 11) {
      if (!this.maxDate || this.year < this.maxDate.year()) {
        this.month = 0
        this.year++
      }
    } else {
      this.month++
    }
  }

  isDayDisabled(day: number): boolean {
    const date = moment({ year: this.year, month: this.month, day })

    if (this.minDate && date.isBefore(this.minDate, 'day')) {
      return true
    }

    return this.maxDate && date.isAfter(this.maxDate, 'day')
  }

  isSelectedDay(day: number): boolean {
    if (!this.selectedDate) return false

    const selected = moment(this.selectedDate, DateFormatEnum.Day)
    const current = moment({ year: this.year, month: this.month, day })

    return selected.isSame(current, 'day')
  }
}
