export enum CartTypeEnum {
  '1ShoppingCart' = 1,
  '24SevenCart' = 2,
  '3dcart' = 42,
  AbleCommerce = 84,
  AccountMate = 94,
  Acumatica = 102,
  AgileIron = 104,
  Agora = 3,
  Amazon = 4,
  ApogeeStoreFront = 64,
  AscenderCart = 38,
  ASecureCart = 65,
  ASPNETStorefront = 5,
  Avactis = 6,
  BigCommerce = 7,
  BluePay = 107,
  Bonanza = 115,
  Braintree = 95,
  BrightStores = 96,
  Capterra = 106,
  Cart32 = 62,
  CartKeeper = 66,
  Cartweaver = 8,
  Celerant = 97,
  Comersus = 9,
  CrystalCommerce = 117,
  CSCart = 10,
  Custom = 51,
  Drupal = 98,
  eBusinessExpress = 43,
  EarthSkater = 47,
  EBay = 35,
  ECWID = 11,
  eJeeva = 89,
  ERPinCloud = 73,
  EStoreware = 67,
  Etsy = 108,
  Exactor = 112,
  EZOT = 59,
  Fancy = 68,
  FlickRocket = 74,
  Fortune3 = 88,
  FoxyCart = 34,
  GoDaddy = 33,
  GoECart = 12,
  GoogleWallet = 13,
  Hikashop = 79,
  IBMiSeries = 76,
  InflatableOffice = 69,
  Intershop = 105,
  iThemesExchange = 81,
  Jet = 109,
  KonaKart = 14,
  KrengelTech = 75,
  Kryptronic = 82,
  LiteCommerce = 15,
  Logicblock = 80,
  Magento = 16,
  MBSBooksellers = 56,
  MivaMerchant = 49,
  MultichannelOrderManager = 17,
  NetSuite = 99,
  NewEgg = 113,
  NicheTank = 40,
  NitroSell = 46,
  nopCommerce = 92,
  Odoo = 100,
  OEConnection = 87,
  OpenCart = 60,
  OpenERP = 72,
  OsCommerce = 18,
  OtherNotlisted = 19,
  PayPalExpress = 37,
  PDshop = 48,
  PinnacleCart = 20,
  Power_eCommerce = 77,
  PrestaShop = 21,
  PrestoCartandPrestoStore = 36,
  Prisme = 50,
  QuickBooks = 22,
  QuickCart = 23,
  Rakuten = 110,
  ReactionCommerce = 103,
  ResponseOMS = 26,
  SalesCart = 24,
  Salesforcecom = 41,
  SharedMall = 53,
  Shipvine = 70,
  Shopify = 25,
  Shopp = 45,
  ShoppingCartElite = 71,
  SimpleSalesTaxforWooCommerce = 101,
  SimplifyCommercebyMastercard = 83,
  Snipcart = 91,
  SparkPay = 52,
  Spree = 44,
  SquareUp = 119,
  Stripe = 57,
  Taxamo = 90,
  TaxCloudjs = 39,
  Ubercart = 55,
  uTypia = 54,
  Vantiv = 78,
  VirtueMart = 63,
  VPASP = 27,
  Walmart = 111,
  WiX = 114,
  WooCommerce = 61,
  WooTax = 85,
  WPeCommerce = 28,
  WPEasyCart = 86,
  X_Cart = 30,
  Xennsoft = 93,
  Yahoo_KingWebmaster = 58,
  Yahoo = 29,
  ZenCart = 31,
  AdobeCommerce = 120,
  Chargebee = 121,
}

export enum IntegrationCartEnum {
  BigCommerce = 'big-commerce',
  Ebay = 'ebay',
  Etsy = 'etsy',
  Quickbooks = 'quickbooks',
  Shopify = 'shopify',
  ShopifySimpleSalesTax = 'simple-sales-tax-25',
  WooCommerce = 'woo-commerce',
  AbilityCommerce = 'ability-commerce',
  Stripe = 'stripe',
  SquareUp = 'squareup',
}

export const IntegrationCartEnumLabel = new Map<IntegrationCartEnum, string>([
  [IntegrationCartEnum.AbilityCommerce, 'Ability Commerce'],
  [IntegrationCartEnum.BigCommerce, 'Big Commerce'],
  [IntegrationCartEnum.Ebay, 'Ebay'],
  [IntegrationCartEnum.Etsy, 'Etsy'],
  [IntegrationCartEnum.Quickbooks, 'QuickBooks'],
  [IntegrationCartEnum.ShopifySimpleSalesTax, 'Simple Sales Tax for Shopify'],
  [IntegrationCartEnum.WooCommerce, 'Woo Commerce'],
  [IntegrationCartEnum.Stripe, 'Stripe'],
  [IntegrationCartEnum.SquareUp, 'Square Up'],
])

export enum MerchantStoreStatusEnum {
  Pending = 'pending',
  Live = 'live',
  Disabled = 'disabled',
}

export const MerchantStoreStatusEnumLabel = new Map<MerchantStoreStatusEnum, string>([
  [MerchantStoreStatusEnum.Pending, 'Testing (pending) stores'],
  [MerchantStoreStatusEnum.Live, 'Live stores'],
  [MerchantStoreStatusEnum.Disabled, 'Disconnected stores'],
])

export const IntegrationStatusEnumLabel = new Map<MerchantStoreStatusEnum, string>([
  [MerchantStoreStatusEnum.Pending, 'Testing (pending) stores'],
  [MerchantStoreStatusEnum.Live, 'Live stores'],
  [MerchantStoreStatusEnum.Disabled, 'Disconnected stores'],
])

export enum StoreTypeEnum {
  Amazon = 'amazon',
  Shopify = 'shopify',
  Linked = 'linked',
  Default = 'default',
}

export enum StoreAutoImportEnum {
  True = 1,
  False = 0,
}

export const StoreAutoImportEnumLabel = new Map<StoreAutoImportEnum, string>([
  [StoreAutoImportEnum.True, 'Auto Import'],
  [StoreAutoImportEnum.False, 'Not Auto Import'],
])
