import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, combineLatest, of } from 'rxjs'
import { catchError, delay, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import * as fromAccountActions from 'src/app/account/actions/account.actions'
import { NotificationService } from 'src/app/notification/services/notification.service'
import * as userSelectors from 'src/app/user/selectors/user.selectors'
import { AuthService } from 'src/app/user/services/auth.service'
import { State as UserState } from 'src/app/user/user.state'
import { State as BillingState } from 'src/app/manage-account/manage-account.state'

import * as loginActions from '../actions/login.actions'
import { LoginActionTypes } from '../actions/login.actions'
import { RegistrationService } from '../services/registration.service'
import { HttpErrorResponse } from '@angular/common/http'
import { State as AccountState } from 'src/app/account/account.state'
import { SocialAuthService } from '@abacritt/angularx-social-login'
import { GoogleLoginProvider } from '@abacritt/angularx-social-login'
import userflow, { Attributes } from 'userflow.js'
import retry from 'async-retry'

import * as transactionActions from 'src/app/transaction/actions/transaction.actions'
import * as accountActions from 'src/app/account/actions/account.actions'
import { AccountActionTypes } from 'src/app/account/actions/account.actions'
import * as billingActions from 'src/app/manage-account/actions/billing.action'
import { IUser } from '../models/iuser'
import { AuthorityLabel } from 'src/app/enums/authority.enums'
import * as accountSelector from 'src/app/account/selectors/account.selectors'
import { AccountService } from 'src/app/account/services/account.service'
import * as setupSelector from 'src/app/setup/selectors/setup.selectors'
import { State as SetupStore } from 'src/app/setup/setup.state'
import * as setupActions from 'src/app/setup/actions/setup.actions'
import { HelpScoutBeaconService } from 'src/app/root/services/helpscout.service'
import { Idle } from '@ng-idle/core'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import moment from 'moment'
import { MessageService } from 'primeng/api'
import { BOTTOM_TOAST_KEY } from 'src/app/public/shell-public-basic/shell-public-basic.component'
import { environment } from 'src/environments/environment'
import LogRocket from 'logrocket'
import * as locationActions from 'src/app/location/actions/location.actions'

@Injectable()
export class LoginEffects {
  constructor(
    private store: Store,
    private authService: AuthService,
    private registrationService: RegistrationService,
    private actions$: Actions,
    private notification: NotificationService,
    private userStore: Store<UserState>,
    private accountStore: Store<AccountState>,
    private router: Router,
    private federatedLoginService: SocialAuthService,
    private billingStore: Store<BillingState>,
    private accountService: AccountService,
    private setupStore: Store<SetupStore>,
    private helpScoutService: HelpScoutBeaconService,
    private messageService: MessageService,
    private idleTimer: Idle
  ) {}

  autoLoginRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.AutoLoginRequest>(LoginActionTypes.AutoLoginRequest),
      switchMap((action) =>
        this.authService.autoLogin(action.payload.token).pipe(
          map(([auth, contactId]) => {
            return new loginActions.UpdateUserAndAccountForAutoLogin({ auth, contactId })
          })
        )
      )
    )
  )

  requestFederatedSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.FederatedLoginSuccess>(LoginActionTypes.FederatedLoginSuccess),
      switchMap((action) => this.authService.federatedLogin(action.payload.socialUser)),
      withLatestFrom(
        this.accountStore.select(accountSelector.selectBusinessName),
        this.accountStore.select(accountSelector.selectStartDate),
        this.userStore.select(userSelectors.selectUserIsMasquerading)
      ),
      tap(([[user, auth], businessName, startDate, isMasquerading]) => {
        if (user && !isMasquerading) {
          this.identifyUser(user, businessName, startDate)
        }
      }),
      map(
        ([[user, auth], businessName, startDate]) => new loginActions.LoginSuccess({ user, auth })
      ),
      catchError((error: HttpErrorResponse) => {
        return of(new loginActions.LoginFailure({ error: this.parseLoginError(error) }))
      })
    )
  )

  updateAccountData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.RequestUserAndAuthFromLocalSuccess>(
        LoginActionTypes.RequestUserAndAuthFromLocalSuccess
      ),
      withLatestFrom(
        this.accountStore.select(accountSelector.selectBusinessName),
        this.accountStore.select(accountSelector.selectStartDate),
        this.userStore.select(userSelectors.selectUserIsMasquerading)
      ),
      switchMap(([action, businessName, startDate, isMasquerading]) =>
        this.authService.getLocalSavedUserAndAuth().pipe(
          tap(([user, auth]) => {
            if (user && !isMasquerading) {
              this.identifyUser(user, businessName, startDate)
            }
          }),
          map(([user, auth]) => new fromAccountActions.RequestAccountData())
        )
      )
    )
  )

  // TODO this is dealing with the endpoint having to user contact

  updateUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.UpdateUser>(LoginActionTypes.UpdateUser),
      withLatestFrom(this.userStore.select(userSelectors.selectAuthorizeUser)),
      map(([action, user]) => ({ ...user, ...action.payload.updateUser })),
      switchMap((user) =>
        this.authService.updateUser(user).pipe(map(() => new loginActions.UpdateUserSuccess()))
      )
    )
  )

  updateUserSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.UpdateUserSuccess>(LoginActionTypes.UpdateUserSuccess),
      withLatestFrom(this.userStore.select(userSelectors.selectUserId)),
      switchMap(([action, id]) =>
        this.authService
          .getUser(id)
          .pipe(map((user) => new loginActions.UpdateStoreUserData({ user })))
      )
    )
  )

  singleUseLogin$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.OneTimeLoginRequest>(LoginActionTypes.OneTimeLoginRequest),
      switchMap((action) =>
        this.authService.oneTimeLogin(action.payload.token).pipe(
          map((auth) => {
            return new loginActions.UpdateSingleUseAuthInStore({ auth })
          }),
          catchError((error: HttpErrorResponse) => {
            return of(new loginActions.LoginFailure({ error: this.parseLoginError(error) }))
          })
        )
      )
    )
  )

  getUserDataFromLocalStorage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.RequestUserAndAuthFromLocal>(
        LoginActionTypes.RequestUserAndAuthFromLocal
      ),
      switchMap((action) =>
        this.authService.getLocalSavedUserAndAuth().pipe(
          map(
            ([user, auth]) => new loginActions.RequestUserAndAuthFromLocalSuccess({ user, auth })
          ),
          catchError((error) =>
            of(new loginActions.RequestUserAndAuthFromLocalFailureAction(error))
          )
        )
      )
    )
  )

  updateAutoAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.UpdateUserAndAccountForAutoLogin>(
        LoginActionTypes.UpdateUserAndAccountForAutoLogin
      ),
      map((_) => new fromAccountActions.GetAccountForAutoLogin())
    )
  )

  updateAutoUserData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.UpdateUserAndAccountForAutoLogin>(
        LoginActionTypes.UpdateUserAndAccountForAutoLogin
      ),
      switchMap((action) => this.authService.getUserForAuto(action.payload.contactId)),
      map((user) => new loginActions.UpdateStoreUserData({ user }))
    )
  )

  requestAccountDataOnLogin$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.LoginSuccess>(LoginActionTypes.LoginSuccess),
      withLatestFrom(
        this.accountStore.select(accountSelector.selectBusinessName),
        this.accountStore.select(accountSelector.selectStartDate),
        this.userStore.select(userSelectors.selectUserIsMasquerading)
      ),
      tap(([action, businessName, startDate, isMasquerading]) => {
        const user = action?.payload?.user
        if (user && !isMasquerading) {
          this.identifyUser(user, businessName, startDate)
        }
      }),
      map((action) => new fromAccountActions.RequestAccountDataLogin())
    )
  )

  resetIdleTimer$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<loginActions.LoginSuccess>(LoginActionTypes.LoginSuccess),
        tap(() => {
          console.log('Idle timer reset')
          this.idleTimer.watch()
        })
      ),
    { dispatch: false }
  )

  saveUserDataToLocal$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.LoginSuccess>(LoginActionTypes.LoginSuccess),
      switchMap((action) =>
        this.authService.saveUserAndAuthToLocal(action.payload.user, action.payload.auth).pipe(
          map(([user, auth]) => new loginActions.SaveUserLocalSuccess({ user, auth })),
          catchError((error: HttpErrorResponse) =>
            of(
              new loginActions.RequestUserAndAuthFromLocalFailureAction({
                error: this.parseLoginError(error),
              })
            )
          )
        )
      )
    )
  )

  lock$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.LockApplication>(LoginActionTypes.LockApplication),
      switchMap((action) =>
        this.authService.lock().pipe(
          tap(() => this.notification.showSuccess({ title: 'Locked' })),
          tap(() =>
            this.accountStore.dispatch(new fromAccountActions.SwitchAccountDataSourceToLocal())
          ),
          tap(() =>
            this.billingStore.dispatch(
              new billingActions.ClearBillingState({ fetchNewData: false })
            )
          ),
          map(([user, auth]) => new loginActions.LockSuccess({ user, auth }))
        )
      )
    )
  )

  unlock$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.UnLockApplication>(LoginActionTypes.UnlockApplication),
      withLatestFrom(this.userStore.select(userSelectors.selectLoginEmail)),
      map(
        ([action, userName]) =>
          new loginActions.LoginRequestAction({ userName, password: action.payload.password })
      )
    )
  )

  logout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.Logout>(LoginActionTypes.Logout),
      map(() => this.authService.logout()),
      delay(500),
      tap(() => this.notification.showSuccess({ title: 'Signed Out' })),
      tap((url) => this.router.navigate(['/'])),
      tap(() =>
        this.billingStore.dispatch(new billingActions.ClearBillingState({ fetchNewData: false }))
      ),
      map(() => new loginActions.LogoutSuccess())
    )
  )

  logoutAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.Logout>(LoginActionTypes.Logout),
      map(() => new fromAccountActions.ClearAccountData())
    )
  )

  logoutWithoutRedirect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.LogoutFromRegistraton>(LoginActionTypes.LogoutFromRegistraton),
      map(() => this.authService.logout()),
      delay(500),
      tap(() => this.notification.showSuccess({ title: 'Signed Out' })),
      map(() => new loginActions.LogoutSuccess())
    )
  )

  loginRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.LoginRequestAction>(LoginActionTypes.LoginRequest),
      switchMap((action) =>
        this.authService
          .login(action.payload.userName, action.payload.password, action.payload.storeInfoGuid)
          .pipe(
            delay(500), // TODO Figure out why we have this hard coded delay in here.
            map(([user, auth]) => new loginActions.LoginSuccess({ user, auth })),
            catchError((error: HttpErrorResponse) => {
              return of(new loginActions.LoginFailure({ error: this.parseLoginError(error) }))
            })
          )
      )
    )
  )

  login2RequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.Login2RequestAction>(LoginActionTypes.Login2Request),
      switchMap((action) =>
        this.authService
          .login2(action.payload.userName, action.payload.password, action.payload.storeInfoGuid)
          .pipe(
            delay(500), // TODO Figure out why we have this hard coded delay in here.
            map(
              ([userSimple, auth]) =>
                new loginActions.Login2Success({
                  userName: action.payload.userName,
                  password: action.payload.password,
                })
            ),
            catchError((error: HttpErrorResponse) => {
              return of(new loginActions.Login2Failure({ error: this.parseLoginError(error) }))
            })
          )
      )
    )
  )

  /**
   * This effect will fire another login, as login2 is only for initial login for onboarding users and does not return the
   * needed data. This should be removed when we can update login2 to return the full data (it may be a breaking change hence
   * this hack)
   */
  login2SuccessEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.Login2Success>(LoginActionTypes.Login2Success),
      map(
        (action) =>
          new loginActions.LoginRequestAction({
            userName: action.payload.userName,
            password: action.payload.password,
          })
      )
    )
  )

  masqueradeRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.MasqueradeLoginRequest>(LoginActionTypes.MasqueradeLoginRequest),
      switchMap((action) =>
        this.authService.masquerade(action.payload.merchantId, action.payload.impersonatorId).pipe(
          map(([user, auth]) => new loginActions.MasqueradeSuccess({ user, auth })),
          catchError((error: HttpErrorResponse) =>
            of(new loginActions.LoginFailure({ error: this.parseLoginError(error) }))
          )
        )
      )
    )
  )

  masqueradeRequestSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.MasqueradeSuccess>(LoginActionTypes.MasqueradeSuccess),
      switchMap((action) =>
        this.authService.saveUserAndAuthToLocal(action.payload.user, action.payload.auth).pipe(
          switchMap(([user, auth]) => {
            this.store.dispatch(new locationActions.InitLocations())
            return of(new loginActions.SaveUserLocalSuccess({ user, auth }))
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              new loginActions.RequestUserAndAuthFromLocalFailureAction({
                error: this.parseLoginError(error),
              })
            )
          )
        )
      )
    )
  )

  masqueradeRequestSuccessResetTransactions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.MasqueradeSuccess>(LoginActionTypes.MasqueradeSuccess),
      map((_) => new transactionActions.InitTransactions()),
      tap((_) => this.router.navigate(['/go']))
    )
  )

  masqueradeRequestSuccessResetAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.MasqueradeSuccess>(LoginActionTypes.MasqueradeSuccess),
      map((_) => new accountActions.RefreshAccountData())
    )
  )

  masqueradeRequestSuccessResetBilling$: Observable<Action> = createEffect(() =>
    combineLatest([
      this.actions$.pipe(
        ofType<loginActions.MasqueradeSuccess>(LoginActionTypes.MasqueradeSuccess)
      ),
      this.actions$.pipe(
        ofType<accountActions.GetAccountDataSuccess>(AccountActionTypes.GetAccountDataSuccess)
      ),
    ]).pipe(
      filter(
        ([action1, action2]) =>
          action1.payload.user.merchantId === action2.payload.account.merchantId
      ),
      map(() => new billingActions.ClearBillingState({ fetchNewData: true }))
    )
  )

  acceptTermsOfService$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | loginActions.AcceptTermsOfService
        | loginActions.AcceptTermsOfServiceExtendConfig
        | loginActions.AcceptLatestTermsOfService
      >(
        LoginActionTypes.AcceptTermsOfService,
        LoginActionTypes.AcceptTermsOfServiceExtendConfig,
        LoginActionTypes.AcceptLatestTermsOfService
      ),
      switchMap((action) =>
        this.authService.acceptTermsOfService().pipe(
          map(() => new loginActions.AcceptTermsOfServiceSuccess()),
          catchError(() => of(new loginActions.AcceptTermsOfServiceFailure()))
        )
      )
    )
  )

  updateUserForTOS$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.AcceptTermsOfServiceSuccess>(
        LoginActionTypes.AcceptTermsOfServiceSuccess
      ),
      withLatestFrom(this.setupStore.select(setupSelector.selectFinalizeLoading)),
      tap(([action, finalizeLoading]) => {
        if (finalizeLoading) {
          this.setupStore.dispatch(new setupActions.SetFinalizeLoading({ loading: false }))
          this.setupStore.dispatch(new setupActions.SetStepLoading({ loading: false }))
          this.setupStore.dispatch(new setupActions.CompleteSetup())
        }
      }),
      switchMap(([action, finalizeLoading]) =>
        this.authService
          .getUser()
          .pipe(map((user) => new loginActions.UpdateStoreUserData({ user })))
      )
    )
  )

  requestResetPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.ResetPasswordRequest>(LoginActionTypes.ResetPasswordRequest),
      switchMap((action) =>
        this.authService
          .resetPasswordRequest(action.payload.email)
          .pipe(map((user) => new loginActions.ResetPasswordRequestSuccess()))
      )
    )
  )

  resetPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.ChangePasswordRequest>(LoginActionTypes.ChangePasswordRequest),
      withLatestFrom(this.userStore.select(userSelectors.selectAuthToken)),
      switchMap(([action, token]) =>
        this.authService
          .resetPassword(token, action.payload.password, action.payload.confirmPassword)
          .pipe(map(([user, auth]) => new loginActions.LoginSuccess({ user, auth })))
      )
    )
  )

  register$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.RegisterRequest>(LoginActionTypes.RegisterRequest),
      switchMap((action) =>
        this.registrationService.register(action.payload.form).pipe(
          tap((emailToVerify) => {
            if (action.payload?.autoLogin === true) {
              const loginPayload: { userName: string; password: string } = {
                userName: action.payload.form.email,
                password: action.payload.form.password,
              }
              this.store.dispatch(new loginActions.LoginRequestAction(loginPayload))
            }
          }),
          map((emailToVerify) => new loginActions.RegisterRequestSuccess({ emailToVerify })),
          catchError((error: HttpErrorResponse) =>
            of(new loginActions.RegisterRequestFailure({ error: this.parseLoginError(error) }))
          )
        )
      )
    )
  )

  registerSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<loginActions.RegisterRequestSuccess>(LoginActionTypes.RegisterRequestSuccess),
        withLatestFrom(this.setupStore.select(setupSelector.selectIsIntegrationsOnboarding)),
        tap(([action, isIntegrationsOnboarding]) => {
          if (isIntegrationsOnboarding) {
            this.messageService.clear(BOTTOM_TOAST_KEY)
            this.setupStore.dispatch(
              new setupActions.SetIsIntegrationsOnboarding({ isIntegrationsOnboarding: false })
            )
          }
        })
      ),
    { dispatch: false }
  )

  requestFederatedLoginForRegister$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.RegisterWithFederated>(LoginActionTypes.RegisterWithFederated),
      switchMap((action) => this.federatedLoginService.signIn(GoogleLoginProvider.PROVIDER_ID)),
      map((socialUser) => new loginActions.RegisterWithFederatedSuccess({ socialUser }))
    )
  )

  registerWithFederatedSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<loginActions.RegisterWithFederatedSuccess>(
        LoginActionTypes.RegisterWithFederatedSuccess
      ),
      switchMap((action) => this.registrationService.registerOauth(action.payload.socialUser)),
      map(([user, auth]) => new loginActions.LoginSuccess({ user, auth })),
      catchError((error: HttpErrorResponse) => {
        return of(new loginActions.LoginFailure({ error: this.parseLoginError(error) }))
      })
    )
  )

  private parseLoginError(error: HttpErrorResponse): string {
    if (typeof error.error === 'string') {
      return error.error
    }

    if (typeof error.message === 'string') {
      return error.message
    }

    if (typeof error.statusText === 'string') {
      return error.statusText
    }

    return 'Unknown error'
  }

  /**
   * Method to identify user to our various integrations.
   */
  private async identifyUser(user: IUser, businessName, startDate) {
    const fullName = `${user.firstName} ${user.lastName}`
    if (environment.production) {
      LogRocket.identify(user.id, {
        name: fullName,
        email: user.username,

        // Custom variables
        merchantId: user.merchantId,
        merchantStartDate: startDate,
        confirmedEmailDate: user.confirmedEmailDate,
        phoneNumber: user.phone,
        authorityLabel: AuthorityLabel.get(user.authority),
      })
    }
    if (!userflow.isIdentified()) {
      // Retry a few times, the library loads async and there isn't a great way to make sure its ready.
      // Billing attributes are identified in billing.effects.ts
      await retry(
        async (bail) =>
          await userflow.identify(user.id, {
            name: fullName,
            email: user.username,
            merchantId: user.merchantId,
            authorityLabel: AuthorityLabel.get(user.authority),
            signed_up_at: user.confirmedEmailDate, // We don't save the actual created at date so this is the next best thing
          }),
        {
          retries: 3,
        }
      )
    }

    const userFlowAttributes: Attributes = {
      name: businessName,
    }

    // The selector returns a default of a year ago for some reason if no value is found, so this is checking that
    if (
      startDate.format(DateFormatEnum.Locale) !==
      moment().subtract(1, 'y').format(DateFormatEnum.Locale)
    ) {
      userFlowAttributes['createdDate'] = moment(startDate).format()
    }

    await retry(
      async (bail) => await userflow.group(user.merchantId.toString(), userFlowAttributes),
      {
        retries: 3,
      }
    )
    await this.helpScoutService.identityUser(user.id, fullName, user.username, user.merchantId)
  }
}
